/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:focus,
*:hover {
  outline: none;
}

/* input:focus,
textarea:focus {
  background-color: #ffff66;
  border: 1px solid #f47e58;
} */

/* .input:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px #719ece;
} */

element {
  outline: none;
}

textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
}

input:focus {
  outline: none !important;
}

textarea:focus,
input:focus {
  outline: none;
}
